import Accordion from '@components/accordion'
import Modal from '@components/modal'
import useAnalytics from '@hooks/use-analytics'
import type { TypeProviderPublic, TypeInsurancePlan } from '@lib/provider-types'
import { Link, Typography } from '@shc/ui'
import { entries, flow, groupBy, map, sortBy } from 'lodash/fp'
import type { Dispatch, SetStateAction } from 'react'

type TypeGroupedInsurancePlans = {
  insurer_name: NonNullable<TypeInsurancePlan['insurer_name']>
  insurance_plans: TypeInsurancePlan[]
}

type ProviderInsuranceModalProps = {
  provider: TypeProviderPublic
  isModalOpen: boolean
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
}

const ProviderInsuranceModal = ({
  provider,
  isModalOpen,
  setIsModalOpen,
}: ProviderInsuranceModalProps) => {
  const groupedInsurancePlans: TypeGroupedInsurancePlans[] = flow(
    groupBy('insurer_name'),
    entries,
    map(
      ([insurer_name, insurance_plans]: [
        TypeInsurancePlan['insurer_name'],
        TypeInsurancePlan[],
      ]) => ({
        insurer_name,
        insurance_plans,
      })
    ),
    sortBy('insurer_name')
  )(provider.insurance_plans)
  const { trackSnowplow } = useAnalytics()

  return (
    <Modal
      size="md"
      contentName="InsuranceModal"
      isOpen={isModalOpen}
      setIsOpen={setIsModalOpen}
      className="flex flex-col"
      data-testid="insurance-modal">
      <Modal.Description>
        <Modal.Title as={Typography} variant="h2" align="center" className="px-7 pb-8">
          Insurance plans accepted
        </Modal.Title>
        <div className="pb-8">
          Sharp accepts most health insurance. Not all plans are listed. Please contact your
          insurance company to verify your insurance type and plan.
        </div>
        <div className="pb-4">
          <Typography variant="body-semibold">In-network insurance plans</Typography>
        </div>
        <div data-testid="insurance-plans-accordion">
          {groupedInsurancePlans.map(({ insurer_name, insurance_plans }, index) => (
            <Accordion
              key={insurer_name}
              title={insurer_name}
              onExpand={() =>
                trackSnowplow({
                  event: { name: 'component_expand', data: {} },
                  contexts: [
                    {
                      name: 'component',
                      data: {
                        component_text: insurer_name,
                      },
                    },
                  ],
                })
              }
              onCollapse={() =>
                trackSnowplow({
                  event: { name: 'component_collapse', data: {} },
                  contexts: [
                    {
                      name: 'component',
                      data: {
                        component_text: insurer_name,
                      },
                    },
                  ],
                })
              }>
              <div>
                {insurance_plans.map(({ id, insurance_plan_type_name }, index) => (
                  <div key={id}>
                    <span>{insurance_plan_type_name}</span>
                    <br />
                    {index === insurance_plans.length - 1 && insurance_plans[index].insurer_url && (
                      <div className="pt-4">
                        <Link
                          noUnderline
                          href={insurance_plans[index].insurer_url!}
                          aria-label="Insurance Website"
                          target="_blank">
                          View insurance website
                        </Link>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </Accordion>
          ))}
        </div>
      </Modal.Description>
    </Modal>
  )
}

export default ProviderInsuranceModal
