'use client'

import { type MouseEvent, useState, useContext } from 'react'
import { Button } from '@shc/ui'
import { type SearchNamespace } from '@app/[locale]/(main)/search/_components/search-session'
import { setSchedulingType } from '@lib/utilities/provider-utilities'
import { useAppConfig } from '@components/client-wrapper'
import AppointmentByPhone from '@components/appointment-by-phone-modal'
import useAnalytics, { type SPContext } from '@hooks/use-analytics'
import {
  BlockContext,
  ItemContext,
  PageContext,
  PlaceContext,
  ProviderContext,
} from '@lib/analytics'
import { trackAlgoliaConversionEvent } from '@lib/utilities/algolia-utilities'
import type { TypeProviderPublic } from '@lib/provider-types'

interface ProviderAppointmentButtonProps extends React.ComponentPropsWithoutRef<'a'> {
  id: string
  provider: TypeProviderPublic
  buttonType: string
}

const ProviderAppointmentButton = ({
  id,
  provider,
  buttonType,
}: ProviderAppointmentButtonProps) => {
  const configs = useAppConfig()
  const epicBookingApptUrl = configs.configs.epic.bookingApptURL
  const schedulingType = setSchedulingType(provider)
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false)
  let hrefValueForButton = undefined
  let phoneSchedulingModal = false

  // Environment contexts
  const pageContext = useContext(PageContext)
  const placeContext = useContext(PlaceContext)
  const providerContext = useContext(ProviderContext)
  const blockContext = useContext(BlockContext)
  const itemContext = useContext(ItemContext)
  const environmentContexts: SPContext[] = [
    pageContext,
    placeContext,
    providerContext,
    blockContext,
    itemContext,
  ].filter((context) => context !== null)

  if (schedulingType === 'Third-party' && provider?.scheduling_url) {
    hrefValueForButton = provider?.scheduling_url
  } else if (schedulingType === 'Online') {
    hrefValueForButton = `${epicBookingApptUrl}${provider.slug}`
  } else {
    phoneSchedulingModal = true
  }

  // Analytics tracking vars
  const { trackSnowplow } = useAnalytics()
  const objectId = provider.id?.toString() ?? '-1'
  const namespace: SearchNamespace = 'providers'

  const onPressBookingAppointmentButton = (e: MouseEvent<any>) => {
    // track button click in Snowplow
    trackSnowplow({
      event: {
        name: 'book_appt_click',
        data: {
          appointment_method: phoneSchedulingModal ? 'Call to schedule' : 'Online booking',
        },
      },
      contexts: [
        ...environmentContexts,
        {
          name: 'component',
          data: {
            component_text: e.currentTarget.textContent,
          },
        },
      ],
    })

    // track open phone modal in Snowplow
    if (phoneSchedulingModal) {
      setIsPhoneModalOpen(true)
      trackSnowplow({
        event: { name: 'modal_open', data: {} },
        contexts: [
          ...environmentContexts,
          {
            name: 'component',
            data: {
              component_text: e.currentTarget.textContent,
            },
          },
          { name: 'modal', data: { modal_name: 'Booking' } },
        ],
      })
    }

    // track conversion in Algolia
    trackAlgoliaConversionEvent(namespace, objectId, `Booked appointment`)
  }

  return (
    <>
      <Button
        id={id}
        href={hrefValueForButton}
        target={schedulingType === 'Third-party' ? '_blank' : undefined}
        rel={schedulingType === 'Third-party' ? 'noopener noreferrer' : undefined}
        aria-label="Book appointment"
        size={buttonType === 'nav' ? 'sm' : undefined}
        width={buttonType === 'nav' ? 'auto' : 'full'}
        data-testid="book-appt-button"
        className={buttonType === 'nav' ? 'invisible lg:inline-flex' : undefined}
        onClick={onPressBookingAppointmentButton}>
        Book&nbsp;appointment
      </Button>

      <AppointmentByPhone
        provider={provider}
        isModalOpen={isPhoneModalOpen}
        setIsModalOpen={setIsPhoneModalOpen}
      />
    </>
  )
}

export default ProviderAppointmentButton
