'use client'

import { useState } from 'react'

import type { TypeProviderPublic } from '@lib/provider-types'
import ProviderInsuranceModal from '@app/[locale]/(main)/doctors/[slug]/_components/provider-insurance-modal'
import { Button, Typography } from '@shc/ui'
import useAnalytics from '@hooks/use-analytics'

export interface ProviderInsurancePlansProps {
  provider: TypeProviderPublic
}

const ProviderInsurancePlans = ({ provider }: ProviderInsurancePlansProps) => {
  const hasMultipleInsurance =
    Array.isArray(provider.insurance_plans) && provider.insurance_plans.length > 1
  const [isInsuranceModalOpen, setIsInsuranceModalOpen] = useState(false)

  const { trackSnowplow } = useAnalytics()
  const openAcceptedPlansModal = () => {
    setIsInsuranceModalOpen(true)
    trackSnowplow({
      event: { name: 'modal_open', data: {} },
      contexts: [
        { name: 'modal', data: { modal_name: 'InsuranceModal' } },
        {
          name: 'provider',
          data: {
            provider_id: provider.id ?? -1,
            provider_name: provider.name_formatted ?? '',
          },
        },
      ],
    })
  }

  return (
    <>
      <div>
        <Typography variant="h2">Insurance plans accepted</Typography>
        <div className="pt-8">
          {provider.name_formatted_with_degrees},
          {hasMultipleInsurance
            ? ` accepts ${
                Array.isArray(provider.insurance_plans) && provider.insurance_plans.length
              } health insurance plans.`
            : ' accepts only 1 insurance plan.'}
        </div>
        <div className="pt-8">
          <Button
            onClick={openAcceptedPlansModal}
            variant="outlined"
            width="auto"
            className="basis-1/2"
            data-testid="insurance-modal-button">
            {hasMultipleInsurance ? 'View accepted plans' : 'View accepted plan'}
          </Button>
        </div>
      </div>

      <ProviderInsuranceModal
        provider={provider}
        isModalOpen={isInsuranceModalOpen}
        setIsModalOpen={setIsInsuranceModalOpen}
      />
    </>
  )
}

export default ProviderInsurancePlans
